import React from "react";

import StaffProfiles from "./staff/staffProfiles";

function Teachers() {
    return(
        <div id="teachers">
          <StaffProfiles />
        </div>
    )
}

export default Teachers;