import React from "react";




function Dashboard() {
  return (
    <>
        {/* this is a signup / signin screen that decides which dashboard to route the user to */}
    </>
  );
}

export default Dashboard;